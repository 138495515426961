// import logo from './logo.svg';
import Header from "./components/Header"
import Tile from "./components/Tile"
import db from "./db"
// import About from './pages/about';
import About from './pages/about'


function App() {
  
  return (
    <div className="app-container">
      <Header />
      {/* <About /> */}
      <div className="projects-container">
        {db.projects.map(project => {
          return <Tile project={project} key={project.name}/>
        })}
          {/* <img className="project-image" src={require('./assets/About_Michael.png').default} alt="Michael McGaughey Logo"/> */}
          <About />
      </div>
    </div>
  );
}

export default App;
