import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import App from "../App"
import history from './history';
import '../App.css';

// import Home from '../pages/Home';
import Project from '../pages/Project';
import About from '../pages/about';

// history.pushState(null, null, location.href);
//     window.onpopstate = function () {
//         history.go(1);
//     };

const Main = () => {
  return (
    <Router history={history}> {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path='/' component={App}></Route>
      {/* <Route path='/about' component={About}></Route> */}
    </Router>
  );
}

export default Main;