import React from "react"
import "./project.css"

function Project(props) {
  return (
    <>
      <div className="project-container">
        <h1 className="title">{props.project.name}</h1>
        {props.project.images.map(image => (
          <div className="image-container" key={image.name}>
            <img className="project-images" src={require(`../assets/${image.name}`).default} alt={image.desc}/>
            <div className="work-container">
              <p className="work-desc">{image.desc}</p>
              <div className="work-done">
                {image.work.map(work => (
                  <p key={work.name}>[ {work.done ? <span className="darker-x">X</span> : <span>&nbsp;</span>} ] {work.name}&nbsp;</p>
                  ))}
            </div>
            </div>
            <div className="border-bottom"/>
          </div>
        ))}
      </div>
    </>
  )
}

export default Project