import React from "react"
import Logo from "../assets/Website_Logo_NoEmail.png"
import db from "../db"

function Header() {
    return (
        <header>
            <a href="/"><img src={Logo} className="logo" alt="Michael McGaughey Logo"/></a>
            <span className="email">{!!db.email.email ? <a href={`mailto:${db.email.email}`}>{db.email.email}</a> : db.email.email}</span>
        </header>
    )
}

export default Header