import React, {useState} from "react";
import Modal from 'react-modal';
import history from "./history";
import Project from "../pages/Project"

function Tile({ project, small }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const stopBrowserBack = callback => {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = () => {
            window.history.pushState(null, "", window.location.href);
            callback();
        };
    };

    const startBrowserBack = () => {
        window.onpopstate = undefined;
        window.history.back();
    };

    const handleOpenModal = () => {
        setModalIsOpen(true)
        stopBrowserBack(handleCloseModal)
    }

    const handleCloseModal = () => {
        startBrowserBack()
        setModalIsOpen(false)
    }
        
    return (
        <>
        {/* <img className={small && "small"} src={require(`../assets/${project.tile_img_name}`).default} onClick={() => history.push(project.url, project)} alt=""/> */}
        <img className={small && "small"} src={require(`../assets/${project.tile_img_name}`).default} onClick={handleOpenModal} alt=""/>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          className="Modal"
          ariaHideApp={false}
        //   onAfterOpen={afterOpenModal}
        //   onRequestClose={closeModal}
        >
              <div style={{position: "relative"}}>
                <button className="close-button" onClick={handleCloseModal}>X</button>
                <Project project={project}/>
              </div>
        </Modal>
        </>
    )
}

export default Tile;