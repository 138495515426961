const db = {
    email: {
        email: "mcgaughey.atl@gmail.com",
    },
    linkedin: {
        address: "linkedin.com/in/mmcgaughey"
    },
    projects: [
        {
            name: "USMC",
            tile_img_name: "Marines_Tile.png",
            url: "usmc",

            images: [
                {
                    name: "USMC_Slide1.png",
                    desc: "Recruiting Poster",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: false
                        },
                    ]
                },
                {
                    name: "USMC_Slide2.png",
                    desc: "Print OOH",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: false
                        },
                    ]
                },
                {
                    name: "USMC_Slide3.png",
                    desc: "Digital OOH And Banner Ads",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: false
                        },
                    ]
                }
            ]
        },
        {
            name: "North Carolina Education Lottery",
            tile_img_name: "NCEL_Tile.png",
            url: "northcarolinaeducationlottery",

            images: [
                {
                    name: "NCEL_Slide1.png",
                    desc: "Point Of Purchase Displays",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: true
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "NCEL_Slide2.png",
                    desc: "Dispenser Topper W/Mechanical",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: true
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "NCEL_Slide3.png",
                    desc: "Shelf Wobbler W/Mechanical",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: true
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "NCEL_Slide4.png",
                    desc: "Point Of Purchase Displays",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: false
                        },
                    ]
                },
                {
                    name: "NCEL_Slide5.png",
                    desc: "In-Store Counter Mats",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: false
                        },
                    ]
                },
                {
                    name: "NCEL_Slide6.png",
                    desc: "POSTERS AND SHELF WOBBLER",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: false
                        },
                    ]
                },
                {
                    name: "NCEL_Slide7.png",
                    desc: "POINT OF PURCHASE DISPLAYS",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: false
                        },
                    ]
                },
            ]
        },
        {
            name: "Shell",
            tile_img_name: "Shell_Tile.png",
            url: "shell",

            images: [
                {
                    name: "Shell_Slide1.png",
                    desc: "BMW Series Print Ads",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: false
                        },
                    ]
                },
                {
                    name: "Shell_Slide2.png",
                    desc: "Print And Digital Ads",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: true
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "Shell_Slide3.png",
                    desc: "Three-Page Gatefold Ad",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "Shell_Slide4.png",
                    desc: "Print And Digital OOH",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "Shell_Slide5.png",
                    desc: "Print And Digital OOH",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                }
            ]
        },
        {
            name: "SCANA Energy",
            tile_img_name: "SCANA_Tile.png",
            url: "scana",

            images: [
                {
                    name: "SCANA_Slide1.png",
                    desc: "Print Ads",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: false
                        },
                    ]
                },
                {
                    name: "SCANA_Slide2.png",
                    desc: "Frames For Animated Banner Ads",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
            ]
        },
        {
            name: "Pennzoil",
            tile_img_name: "Pennzoil_Tile.png",
            url: "pennzoil",

            images: [
                {
                    name: "PZ_Slide1.png",
                    desc: "Two-Page Spread Print Ad",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: false
                        },
                    ]
                },
                {
                    name: "PZ_Slide2.png",
                    desc: "Three-Panel Print Ad",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "PZ_Slide3.png",
                    desc: "SXSW Live Mario Kart Event",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "PZ_Slide4.png",
                    desc: "Digital Banner Ads",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "PZ_Slide5.png",
                    desc: "Poster And Print Ad",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                }
            ]
        },
        {
            name: "Georgia Commute Options",
            tile_img_name: "GCO_Tile.png",
            url: "gco",

            images: [
                {
                    name: "GCO_Slide1.png",
                    desc: "Digital OOH",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "GCO_Slide2.png",
                    desc: "Print Posters",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "GCO_Slide3.png",
                    desc: "Retractable Banners and Tent",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                }
            ]
        },
        {
            name: "Quaker State",
            tile_img_name: "QuakerState_Tile.png",
            url: "quakerstate",

            images: [
                {
                    name: "QS_Slide1.png",
                    desc: "Station Domination Graphics",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: true
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "QS_Slide2.png",
                    desc: "Station Domination Graphics",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: true
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "QS_Slide3.png",
                    desc: "Station Domination",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: true
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "QS_Slide4.png",
                    desc: "Station Domination Train Wrap",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: true
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "QS_Slide5.png",
                    desc: "Station Domination Train Wrap",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: true
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "QS_Slide6.png",
                    desc: "Station Domination Bus Wrap",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: true
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                }
            ]
        },
        {
            name: "Spirit Airlines",
            tile_img_name: "Spirit_Tile.png",
            url: "spirit",

            images: [
                {
                    name: "Spirit_Slide1.png",
                    desc: "Print Poster And Collateral",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: true
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "Spirit_Slide2.png",
                    desc: "Email Headers And Banner Ads",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: true
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "Spirit_Slide3.png",
                    desc: "Print And Digital OOH",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: true
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "Spirit_Slide4.png",
                    desc: "In-Airport Digital Signage",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "Spirit_Slide5.png",
                    desc: "Email Promo Banners",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                }
            ]
        },
        {
            name: "U.S. Virgin Islands",
            tile_img_name: "USVI_Tile.png",
            url: "usvi",

            images: [
                {
                    name: "USVI_Slide1.png",
                    desc: "Print Ads",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "USVI_Slide2.png",
                    desc: "Airport Posters",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "USVI_Slide3.png",
                    desc: "Print And Digital Ads",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: true
                        },
                        {
                            name: "Retouching",
                            done: false
                        },
                    ]
                },
                {
                    name: "USVI_Slide4.png",
                    desc: "Print And Digital Ads",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: true
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "USVI_Slide5.png",
                    desc: "Trade Show Booth",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                }
            ]
        },
        {
            name: "Jiffy Lube",
            tile_img_name: "JiffyLube_Tile.png",
            url: "jiffylube",

            images: [
                {
                    name: "JL_Slide1.png",
                    desc: "In-Store Posters",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "JL_Slide2.png",
                    desc: "Direct Mail",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: false
                        },
                    ]
                },
                {
                    name: "JL_Slide3.png",
                    desc: "Print Ads",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "JL_Slide4.png",
                    desc: "Print OOH",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: false
                        },
                        {
                            name: "Retouching",
                            done: false
                        },
                    ]
                }
            ]
        },
        {
            name: "J. Walter Thompson",
            tile_img_name: "JWT_Tile.png",
            url: "jwt",

            images: [
                {
                    name: "JTW_Slide0.png",
                    desc: "INTERACTIVE AGENCY APP USED BY NEW BUSINESS TEAMS TO PRESENT VIDEO REELS TO POTENTIAL CLIENTS",
                    work: [
                        {
                            name: "Production",
                            done: true
                        },
                        {
                            name: "Design/Layout",
                            done: true
                        },
                        {
                            name: "Retouching",
                            done: true
                        },
                    ]
                },
                {
                    name: "JWT_Slide1.png",
                    desc: "",
                    work: [

                    ]
                },
                {
                    name: "JWT_Slide2.png",
                    desc: "",
                    work: [

                    ]
                },
                {
                    name: "JWT_Slide3.png",
                    desc: "",
                    work: [

                    ]
                },
                {
                    name: "JWT_Slide4.png",
                    desc: "",
                    work: [

                    ]
                },
                {
                    name: "JWT_Slide5.png",
                    desc: "",
                    work: [

                    ]  
                },
                {
                    name: "JWT_Slide6.png",
                    desc: "",
                    work: [

                    ]  
                },
                {
                    name: "JWT_Slide7.png",
                    desc: "",
                    work: [

                    ]  
                },
                {
                    name: "JWT_Slide8.png",
                    desc: "",
                    work: [

                    ]  
                },
                {
                    name: "JWT_Slide9.png",
                    desc: "",
                    work: [

                    ]  
                },
                {
                    name: "JWT_Slide10.png",
                    desc: "",
                    work: [

                    ]  
                },
                {
                    name: "JWT_Slide11.png",
                    desc: "",
                    work: [

                    ]  
                },
                {
                    name: "JWT_Slide12.png",
                    desc: "",
                    work: [

                    ]  
                },
                {
                    name: "JWT_Slide13.png",
                    desc: "",
                    work: [

                    ]  
                },
                {
                    name: "JWT_Slide14.png",
                    desc: "",
                    work: [

                    ]  
                },
                {
                    name: "JWT_Slide15.png",
                    desc: "",
                    work: [

                    ]  
                },
                {
                    name: "JWT_Slide16.png",
                    desc: "",
                    work: [

                    ]  
                },
                {
                    name: "JWT_Slide17.png",
                    desc: "",
                    work: [

                    ]  
                },
                {
                    name: "JWT_Slide18.png",
                    desc: "",
                    work: [

                    ]  
                },
                {
                    name: "JWT_Slide19.png",
                    desc: "",
                    work: [

                    ]  
                },
                {
                    name: "JWT_Slide20.png",
                    desc: "",
                    work: [

                    ]  
                },
                {
                    name: "JWT_Slide21.png",
                    desc: "",
                    work: [

                    ]  
                },
                {
                    name: "JWT_Slide22.png",
                    desc: "",
                    work: [

                    ]  
                },
                {
                    name: "JWT_Slide23.png",
                    desc: "",
                    work: [

                    ]  
                }
            ]
        },
        // {
        //     name: "Michael McGaughey",
        //     tile_img_name: "About_Michael.png",
        //     url: "michael_mcgaughey",

        //     images: [
        //         {
        //             name: "JL_Slide4.png",
        //             desc: "Billboards (OOH)",
        //             work: [
        //                 {
        //                     name: "Production",
        //                     done: true
        //                 },
        //                 {
        //                     name: "Design/Layout",
        //                     done: false
        //                 },
        //                 {
        //                     name: "Retouching",
        //                     done: false
        //                 },
        //             ]
        //         }
        //     ]
        // },
    ]
}

export default db;