import React, {useState} from "react";
import "./project.css"
import Modal from 'react-modal';
import db from "../db"

function About() {


    const [modalIsOpen, setModalIsOpen] = useState(false);

    const stopBrowserBack = callback => {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = () => {
            window.history.pushState(null, "", window.location.href);
            callback();
        };
    };

    const startBrowserBack = () => {
        window.onpopstate = undefined;
        window.history.back();
    };

    const handleOpenModal = () => {
        setModalIsOpen(true)
        stopBrowserBack(handleCloseModal)
    }

    const handleCloseModal = () => {
        startBrowserBack()
        setModalIsOpen(false)
    }

  return (
    <>
      {/* <div className="about-container"> */}
        {/* <button className="about-button" onClick={handleOpenModal}>About</button> */}
        <img className="project-image" onClick={handleOpenModal} src={require('../assets/About_Michael.png').default} alt="Michael McGaughey Logo"/>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          className="Modal"
          ariaHideApp={false}
        //   onAfterOpen={afterOpenModal}
        //   onRequestClose={closeModal}
        >
          <div className="about-container" style={{position: "relative"}}>
            <button className="about-close-button" onClick={handleCloseModal}>X</button>
            <div>
              <img className="profile-image" src={require('../assets/Profile_1000x1000.png').default} alt="Michael McGaughey Logo"/>
              <p className="about-text">Michael McGaughey’s career includes more than ten years of experience at some of the advertising industry’s top agencies. As a designer and production artist, he is that rare individual who can successfully complete creative projects from concept through delivery.</p>

              <p className="about-text">A graduate of the Art Institute of Philadelphia where he majored in graphic design, Michael’s extensive professional experience includes work at advertising agencies, prepress shops, color houses and printing companies. He has hands-on experience in every aspect of digital and print design and production including: concepting, layout, typesetting, page building, troubleshooting files, scanning, retouching and color management.</p>
              
              <p className="about-text">Michael is a problem solver. He takes lessons learned from every project to create workflows that reduce cost and keep projects on schedule. He is fast, accurate and quality-driven.</p>
              
              <p className="about-text">Clients include: United States Marine Corps, North Carolina Lottery, U.S. Virgin Islands, Royal Dutch Shell, FEMA, Spirit Airlines, SCANA Energy, Atlanta Falcons, Atlanta United, Mercedes-Benz Stadium, Time Life, Church’s Chicken, Transamerica, Smithsonian Magazine, Discovery Channel, The Home Depot, Kiplinger’s Magazine, Abercrombie & Fitch, Blue Cross Blue Shield and Solvay Pharmaceuticals.</p>
              <div className="about-email">
                <a href={`mailto:${db.email.email}`}><img className="email-image" src={require('../assets/email_logo.svg').default} alt="Email Logo"/><span className="about-email">{!!db.email.email ? <a href={`mailto:${db.email.email}`}>{db.email.email}</a> : db.email.email}</span></a>
              </div>
              <div className="about-linkedIn">
                <a href={`https://${db.linkedin.address}`} target="_blank" rel="noreferrer"><img className="linkedin-image" src={require('../assets/LI-In-Bug.png').default} alt="LinkedIn Logo"/><span className="about-linkedin">{!!db.linkedin.address ? <a href={`https://${db.linkedin.address}`} target="_blank" rel="noreferrer">{db.linkedin.address}</a> : db.linkedin.address}</span></a>
              </div>
            </div>
          </div>
        </Modal>
      {/* </div> */}
    </>
  )
}

export default About